/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * March 2022
 */

import {
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonIcon,
    IonButton,
} from "@ionic/react";
import React from "react";
import {useHistory} from "react-router";
import {
    cardOutline, cubeOutline,
    locateOutline,
    logoAppleAppstore,
    logoFacebook,
    logoGooglePlaystore,
    logoInstagram,
    logoLinkedin,
    logoTiktok, logoWhatsapp
} from "ionicons/icons";

//style
import "../../css/components/webFooter.css";


const WebFooter: React.FC = () => {
    const history = useHistory();

    const goToHome = () => {
        history.push('/inicio')
    }
    const goToGoogleApp = () => {
        // https://play.google.com/store/apps/details?id=mx.nbio&hl=es_MX&gl=US
    }
    const goToIphoneApp = () => {
        // https://apps.apple.com/us/app/nbio/id1605823846
    }
    return (
        <div className={"web-footer ion-hide-lg-down"}>
            <IonGrid className={'ion-no-padding'}>
                <IonRow className={'web-footer-section-top'}>
                    <IonCol className={'web-footer-sp'}>
                        <IonIcon className={'web-footer-sp-icon'} icon={locateOutline}></IonIcon>
                        <div>
                            <h3 className={''}>Seguimiento en tiempo real</h3>
                            <div className={''}>
                                Monitorea la posición, temperatura y humedad de tus pedidos.
                            </div>
                        </div>
                    </IonCol>

                    <IonCol className={'web-footer-sp'}>
                        <IonIcon className={'web-footer-sp-icon'} icon={cardOutline}></IonIcon>
                        <div>
                            <h3 className={''}>Métodos de pago</h3>
                            <div className={''}>
                                Paga seguro con MercadoPago. Aceptamos TDC/TDD, efectivo y con terminal punto de venta.
                            </div>
                        </div>
                    </IonCol>

                    <IonCol className={'web-footer-sp'}>
                        <IonIcon className={'web-footer-sp-icon'} icon={cubeOutline}></IonIcon>
                        <div>
                            <h3 className={''}>Envíos</h3>
                            <div className={''}>
                                Enviamos a toda la República Mexicana.
                            </div>
                        </div>
                    </IonCol>

                    <IonCol className={'web-footer-sp'}>
                        <IonIcon className={'web-footer-sp-icon'} icon={logoWhatsapp}></IonIcon>
                        <div>
                            <h3 className={''}>Atención a clientes</h3>
                            <div className={''}>
                                Soporte por Teléfono al &nbsp;
                                <a href={'tel:+525545772810'} target={'_blank'}>
                                    (554) 577-2810
                                </a>
                            </div>
                        </div>
                    </IonCol>
                </IonRow>
                <div className={'web-footer-section-bottom'}>
                    <IonRow className={' ion-justify-content-center'}>
                        <IonCol size-lg={12}>
                            <IonRow>
                                <IonCol className={'ion-align-items-center ion-text-center'}>
                                    <IonText><b>Descarga la App</b></IonText>
                                    <div className={'web-footer-wrapper-download-btns'}>
                                        <a href={'https://apps.apple.com/us/app/nbio/id1605823846'} target={'_blank'}>
                                            <IonButton className={'download-btn'}>
                                                <IonIcon icon={logoAppleAppstore} size={'large'}></IonIcon>
                                                <div className={'download-btn-label'}>
                                                    <div><small>Descarga en</small></div>
                                                    <div><b>App Store</b></div>
                                                </div>
                                            </IonButton>
                                        </a>
                                        <a href={'https://play.google.com/store/apps/details?id=mx.nbio&hl=es_MX&gl=US'} target={'_blank'}>
                                            <IonButton className={'download-btn'}>
                                                <IonIcon icon={logoGooglePlaystore} size={'large'}
                                                         onClick={() => goToGoogleApp()}></IonIcon>
                                                <div className={'download-btn-label'}>
                                                    <div><small>Descarga en</small></div>
                                                    <div><b>Google Play</b></div>
                                                </div>
                                            </IonButton>
                                        </a>
                                    </div>
                                </IonCol>

                                <IonCol size-lg={6} className={'ion-align-items-center ion-text-center'}>
                                    <div className={'web-footer-logo'} onClick={() => goToHome()}></div>
                                    <IonText>
                                        <small>
                                            Rancho el Palmar 53, Campestre Coyoacán, Coyoacán, 04938, CDMX.<br/>
                                            NBIO Healthcare Logistics ® es una marca registrada de Envíalo México S.A. de
                                            C.V.
                                        </small>
                                    </IonText>
                                </IonCol>

                                <IonCol className={'ion-align-items-center ion-text-center'}>
                                    <IonText><b>Síguenos</b></IonText>
                                    <div className={'web-footer-wrapper-icons'}>
                                        <a className={'web-footer-icon'}
                                           href={"https://www.facebook.com/people/NBIO-Farmacia/100070094943617/"}
                                           target="_blank">
                                            <IonIcon icon={logoFacebook}></IonIcon>
                                        </a>
                                        <a className={'web-footer-icon'}
                                           href={"https://www.instagram.com/nbio_delivery/"}
                                           target="_blank">
                                            <IonIcon icon={logoInstagram}></IonIcon>
                                        </a>
                                        <a className={'web-footer-icon'}
                                           href={"https://www.linkedin.com/company/nbio-salud/"}
                                           target="_blank">
                                            <IonIcon icon={logoLinkedin}></IonIcon>
                                        </a>
                                        <a className={'web-footer-icon'}
                                           href={"https://www.tiktok.com/@nbiomx"}
                                           target="_blank">
                                            <IonIcon icon={logoTiktok}></IonIcon>
                                        </a>
                                    </div>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    <IonRow className={'ion-justify-content-center ion-margin-top'}>
                        <IonCol size-lg={6} className={'ion-text-center'}>
                            <IonText><a href={"/preguntas-frecuentes"}><b>Preguntas frecuentes | </b></a></IonText>
                            <IonText><a href={"/politicas-de-privacidad"}><b>Políticas de privacidad | </b></a></IonText>
                            <IonText><a href={"/terminos-y-condiciones"}><b>Términos y condiciones | </b></a></IonText>
                            <IonText><a href={"/soporte"}><b>Soporte</b></a></IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className={'web-footer-nodeport'}>
                        <IonCol className={'ion-text-center'}>
                            <small>
                                Powered by <a href={"https://nodeport.co/"} target="_blank">Nodeport</a>
                            </small>
                        </IonCol>
                    </IonRow>
                </div>
            </IonGrid>
        </div>
    )
}

// @ts-ignore
export default WebFooter;