/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */

import axios from 'axios';
import {image} from "ionicons/icons";
import {AddressType, InvoiceType} from "../data_types/dataTypes";
const LOCAL_STORAGE_TOKEN = 'nbio-app-utils-token';
const config = () =>{
    return {
        withCredentials: true,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem(LOCAL_STORAGE_TOKEN)}`
        }
    }
}
const configWithUploadProgress = (progressEvent:any) =>{
    return {
        withCredentials: true,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem(LOCAL_STORAGE_TOKEN)}`
        },
        onUploadProgress: (pEvent:any) => progressEvent(pEvent)
    }
}
const API = {
    API_URL: process.env.REACT_APP_API_URL,
    users: {
        create(user: any) {
            return axios.post(`${API.API_URL}/users/create`, user, config()).then((res) =>{
                console.log(res)
                let token = res.data.result.token;
                localStorage.setItem(LOCAL_STORAGE_TOKEN,token);
                return res;
            }).catch((ex) =>{
                return Promise.reject(ex);
            })
        },
        getMe() {
            return axios.get(`${API.API_URL}/users/me`, config());
        },
        editMe(data:object) {
            return axios.post(`${API.API_URL}/users/me/updateProfile`, data,config());
        },
        changeMyPassword(password:string,password2:string){
            const data = {
                password: password,
                password2: password2,
            }
            return axios.post(`${API.API_URL}/users/me/updatePassword`, data, config());
        },
        async login(email: string, password: string) {
            const data = {
                email: email,
                password: password,
            }
            const res = await axios.post(`${API.API_URL}/users/login`, data, config()).catch((ex) =>{
                return Promise.reject(ex);
            });
            let token = res.data.result.token;
            localStorage.setItem(LOCAL_STORAGE_TOKEN,token);
            return Promise.resolve(res);
        },
        async loginWithSocial(token:string,provider = 'meta',extraData = {}) {
            const data = {
                token: token,
                provider:provider,
                ...extraData
            }
            return axios.post(`${API.API_URL}/users/login/social`, data, config()).then((res) =>{
                let token = res.data.result.token;
                localStorage.setItem(LOCAL_STORAGE_TOKEN,token);
                return res;
            }).catch((ex) =>{
                return Promise.reject(ex);
            })
        },
        logout() {
            return axios.post(`${API.API_URL}/users/me/logout`, {}, config());
        },
        deleteOwnAccount() {
            return axios.post(`${API.API_URL}/users/me/deleteAccount`, {}, config());
        },
        updateProfilePicture(imageBase64: string) {
            const data = {
                img: imageBase64
            }
            return axios.post(`${API.API_URL}/users/me/updateProfilePicture`, data, config());
        },
        updateFcmToken(fcmToken: string) {
            const data = {
                fcmToken: fcmToken
            }
            return axios.post(`${API.API_URL}/users/me/updateFcmToken`, data, config());
        },
        getAuthToken(){
            return localStorage.getItem(LOCAL_STORAGE_TOKEN);
        },
        requestResetPwd(email: string) {
            const data = {
                email: email
            }
            return axios.post(`${API.API_URL}/users/requestPasswordReset`, data, config());
        },
        validatePasswordResetCode(email:string,code:string){
            const data = {
                email: email,
                code:code
            }
            return axios.post(`${API.API_URL}/users/validatePasswordResetCode`, data, config());
        },
        updatePassword(email:string,password:string,password2:string,code:string){
            const data = {
                email:email,
                password: password,
                password2: password2,
                code:code
            }
            return axios.post(`${API.API_URL}/users/updatePassword`, data, config());
        },
        isMyProfileComplete() {
            return axios.get(`${API.API_URL}/users/me/isProfileComplete`, config());
        },
    },
    categories: {
        get(page = 1, pageSize = 50) {
            return axios.get(`${API.API_URL}/categories?page=${page}&pageSize=${pageSize}`, config())
        },
        getCategory(categoryId: string, page = 1, pageSize = 50,filters = {}) {
            filters = encodeURIComponent(JSON.stringify(filters));
            return axios.get(`${API.API_URL}/categories/${categoryId}?page=${page}&pageSize=${pageSize}&filters=${filters}`,
                config()
            )
        }
    },
    brands: {
        get(page = 1, pageSize = 50) {
            return axios.get(`${API.API_URL}/brands?page=${page}&pageSize=${pageSize}`, config())
        },
        getBrand(categoryId: string, page = 1, pageSize = 50,filters = {}) {
            filters = encodeURIComponent(JSON.stringify(filters));
            return axios.get(`${API.API_URL}/brands/${categoryId}?page=${page}&pageSize=${pageSize}&filters=${filters}`,
                config()
            )
        }
    },
    discounts: {
        get(page = 1, pageSize = 50) {
            return axios.get(`${API.API_URL}/discounts/explore?page=${page}&pageSize=${pageSize}`, config())
        },
    },
    orders: {
        getMyOrders(page = 1, pageSize = 50,orderType = '') {
            return axios.get(`${API.API_URL}/orders/myOrders?page=${page}&pageSize=${pageSize}&orderType=${orderType}`, config())
        },
        getOrder(orderId: string) {
            return axios.get(`${API.API_URL}/orders/${orderId}`, config())
        },
        updatePrescription(orderId:string,itemId:string,prescriptionId:string){
            const data = {
                prescriptionId:prescriptionId
            }
            return axios.post(`${API.API_URL}/orders/${orderId}/items/${itemId}/updatePrescription`,data,config())
        },
        assigned(page = 1, pageSize = 50,orderType = ''){
            return axios.get(`${API.API_URL}/orders/assigned?page=${page}&pageSize=${pageSize}&orderType=${orderType}`, config())
        },
        cancelOrder(orderId:string,reason:string){
            return axios.post(`${API.API_URL}/orders/${orderId}/cancelOwn`, {reason:reason},config())

        },
        requestLiveData(orderId:string){
            return axios.post(`${API.API_URL}/orders/${orderId}/requestLiveData`, {}, config());
        },
        track(orderId:string){
            return axios.get(`${API.API_URL}/orders/${orderId}/tracking`,  config());
        },
        gpsTrack(orderId:string){
            return axios.get(`${API.API_URL}/orders/${orderId}/gpsTracking`,  config());
        },
        getActiveOrder() {
            return axios.get(`${API.API_URL}/orders/active`, config())
        },
        uploadID: (orderId: string, photoId: object, cartItemId: string, copyOfDocumentId: string) => {
            const data = {
                copyOfDocumentId: copyOfDocumentId,
                id: photoId,
                cartItemId: cartItemId
            }
            return axios.post(`${API.API_URL}/orders/${orderId}/items/${cartItemId}/idUpload`, data, config());
        },
        lastKnownGPSPosition(orderId:string){
            return axios.get(`${API.API_URL}/orders/${orderId}/lastKnownGPSPosition`,  config());
        },
    },
    reviews:{
        getByOrderId(orderId:string) {
            return axios.get(`${API.API_URL}/reviews/byOrderId?orderId=${orderId}`, config())
        },
        completeReview(reviewId:string,answers:object){
            return axios.post(`${API.API_URL}/reviews/${reviewId}/complete`,answers, config())
        },
        skipReview(reviewId:string){
            return axios.post(`${API.API_URL}/reviews/${reviewId}/skip`,{}, config())
        },
        getUnfinishedReviews(){
            return axios.get(`${API.API_URL}/reviews/my-unfinished`,config())

        }
    },
    products: {
        get() {
            return axios.get(`${API.API_URL}/products/`, config());
        },
        getTop() {
            return axios.get(`${API.API_URL}/products/top`, config());
        },
        getRecommended(id: string) {
            return axios.get(`${API.API_URL}/products/${id}/recommended`, config());
        },
        getProduct(sku: string) {
            return axios.get(`${API.API_URL}/products/${sku}`, config());
        },
        autocomplete(query:string){
            return axios.get(`${API.API_URL}/search/autocomplete?q=${query}`, config());
        },
        search(query: string,page=1,pageSize=20,filters={}) {
            filters = encodeURIComponent(JSON.stringify(filters));
            return axios.get(`${API.API_URL}/search?q=${query}&page=${page}&pageSize=${pageSize}&filters=${filters}`, config());
        },
        searchHistory() {
            return axios.get(`${API.API_URL}/search/history`, config());
        },
    },
    checkout:{
        createCheckout:(cartId:string) =>{
            const data = {
                cartId:cartId
            }
            return axios.post(`${API.API_URL}/checkouts/create`,data,config());
        },
        updateCheckout(checkout:object,checkoutId:string){
            const data = checkout;
            return axios.post(`${API.API_URL}/checkouts/${checkoutId}/update`,data,config());
        },
        generatePaymentIntent(checkoutId:string,data:object = {}){
            return axios.post(`${API.API_URL}/checkouts/${checkoutId}/createPaymentIntent`,data,config());
        },
        getCheckout(checkoutId:string){
            return axios.get(`${API.API_URL}/checkouts/${checkoutId}`,config());
        },
        setGratuity(checkoutId: string,gratuityType:string,amount:number){
            const data = {
                gratuityType:gratuityType,
                amount:amount
            }
            return axios.post(`${API.API_URL}/checkouts/${checkoutId}/setGratuity`,data,config());
        },
        check(checkoutId: string){
            return axios.post(`${API.API_URL}/checkouts/${checkoutId}/check`,{},config());
        }



    },
    shippingMethods:{
        getShippingMethods(checkoutId:string){
            return axios.get(`${API.API_URL}/checkouts/${checkoutId}/shippingMethods`,config());
        },
        getDeliveryBlocks(){
            return axios.get(`${API.API_URL}/programmed-orders`,config());
        },
    },
    cart: {
        addProduct: (productID: string, quantity: number, cartId = null,adId = null) => {
            const data = {
                id: productID,
                quantity: quantity,
                isCustomProduct: false,
                cartId: cartId,
                adId:adId
            }
            return axios.post(`${API.API_URL}/cart/add`, data, {});
        },
        getCart: (id: string) => {
            return axios.get(`${API.API_URL}/cart/${id}`)
        },
        updateProduct: (cartId: string, cartItemId: string, quantity: number,prescriptionId?: string) => {
            const data = {
                cartItemId: cartItemId,
                quantity: quantity,
                cartId: cartId,
                prescriptionId:prescriptionId
            }
            return axios.post(`${API.API_URL}/cart/update`, data, {});
        },
        removeProduct: (cartId: string, cartItemId: string) => {
            const data = {
                cartItemId: cartItemId,
                cartId: cartId
            }
            return axios.post(`${API.API_URL}/cart/remove`, data, {});
        },
        uploadID: (cartId: string, photoId: object, cartItemId: string, copyOfDocumentId: string) => {
            const data = {
                copyOfDocumentId: copyOfDocumentId,
                cartId: cartId,
                id: photoId,
                cartItemId: cartItemId
            }
            return axios.post(`${API.API_URL}/cart/${cartId}/${cartItemId}/idUpload`, data, config());
        },
        getPrescriptions:(cartId: string) =>{
            return axios.get(`${API.API_URL}/cart/${cartId}/prescriptions`)
        },
        getPhotoIds:(cartId: string) =>{
            return axios.get(`${API.API_URL}/cart/${cartId}/photoIds`)
        },
        addCoupon: (cartId: string, couponId: string) => {
            const data = {
                couponId: couponId
            }
            return axios.post(`${API.API_URL}/cart/${cartId}/addCoupon`, data, config());
        },
        removeCoupon: (cartId: string, couponId: string) => {
            const data = {
                couponId: couponId
            }
            return axios.post(`${API.API_URL}/cart/${cartId}/removeCoupon`, data, config());
        },
    },
    prescriptions:{
        create:(documentId:string,img:string,issueDate:Date,copyOfPrescriptionId:null) =>{
            const data = {
                documentId: documentId,
                img: img,
                issueDate:issueDate,
                copyOfPrescriptionId: copyOfPrescriptionId
            }
            return axios.post(`${API.API_URL}/prescriptions/create`, data, config());
        }
    },
    addresses:{
        get:() =>{
          return axios.get(`${API.API_URL}/addresses`,config());
        },
        create:(address:AddressType) =>{
            return axios.post(`${API.API_URL}/addresses/create`, address, config());
        },
        delete:(addressId:string) =>{
            return axios.post(`${API.API_URL}/addresses/${addressId}/remove`, {}, config());
        },
        edit:(addressId:string,address:AddressType) =>{
            return axios.post(`${API.API_URL}/addresses/${addressId}/update`, address, config());
        },
        getDefault:() =>{
            return axios.get(`${API.API_URL}/addresses/default`,config());
        },
        getAddress:(id:string) =>{
            return axios.get(`${API.API_URL}/addresses/${id}`,config());
        },
        reverseGeocoding:(latitude:number,longitude:number) =>{
            return axios.get(`${API.API_URL}/addresses/reverse-geocoding?lat=${latitude}&lon=${longitude}`,config());
        }
    },
    invoices:{
        get:() =>{
            return axios.get(`${API.API_URL}/invoices`,config());
        },
        create:(invoice:InvoiceType) =>{
            return axios.post(`${API.API_URL}/invoices/create`, invoice, config());
        },
        delete:(invoiceId:string) =>{
            return axios.post(`${API.API_URL}/invoices/${invoiceId}/remove`, {}, config());
        },
        edit:(invoiceId:string,invoice:InvoiceType) =>{
            return axios.post(`${API.API_URL}/invoices/${invoiceId}/update`, invoice, config());
        },
        getDefault:() =>{
            return axios.get(`${API.API_URL}/invoices/default`,config());
        },
        getInvoice:(id:string) =>{
            return axios.get(`${API.API_URL}/invoices/${id}`,config());
        }
    },
    operator:{
        tickets:{
            create:(img:string, onUploadProgress:any) =>{
                const data = {
                    img: img
                }
                return axios.post(`${API.API_URL}/tickets/create`, data, configWithUploadProgress(onUploadProgress));
            }
        },
        orders:{
            uploadTicket(orderId:string,ticketId:string){
                const data = {
                    ticketId:ticketId
                }
                return axios.post(`${API.API_URL}/orders/${orderId}/addTicket`,data,config())
            }
        },
        markOrderAsOnRoute(orderId:string){
            return axios.post(`${API.API_URL}/orders/${orderId}/markOrderAsOnRoute`,{},config())
        },
        markOrderAsDelivering(orderId:string){
            return axios.post(`${API.API_URL}/orders/${orderId}/markOrderAsDelivering`,{},config())
        },
        markOrderAsDelivered(orderId:string){
            return axios.post(`${API.API_URL}/orders/${orderId}/markOrderAsDelivered`,{},config())
        },
        markOrderAsWorkingOnOrder(orderId:string){
            return axios.post(`${API.API_URL}/orders/${orderId}/markAsWorkingOnOrder`,{},config())
        }
    },
    paymentMethods: {
        get() {
            return axios.get(`${API.API_URL}/paymentMethods/`, config());
        },
        getDefault(){
            return axios.get(`${API.API_URL}/paymentMethods/default`, config());

        },
        makeDefault(id:string){
            return axios.post(`${API.API_URL}/paymentMethods/${id}/makeDefault`,{},config())
        },
        delete(id:string){
            return axios.post(`${API.API_URL}/paymentMethods/${id}/delete`,{},config())
        }
    },
    ads: {
        getAdPopup() {
            return axios.get(`${API.API_URL}/content/pp`, config());
        },
        reportClick(adId:string){
            return axios.post(`${API.API_URL}/content/${adId}/rc`,{},config())
        }
    },
    regions: {
        getRegion(regionId:string) {
            return axios.get(`${API.API_URL}/regions/${regionId}`, config());
        },
    },
    routes: {
        getMyRoutes(page = 1,pageSize = 50) {
            return axios.get(`${API.API_URL}/routes/my-routes?page=${page}&pageSize=${pageSize}`, config());
        },
    },
    t:{
        rao(){
            return axios.get(`${API.API_URL}/content/rao`, config());
        }
    },
    coupons:{
        getMyCoupons(){
            return axios.get(`${API.API_URL}/coupons/my-coupons`, config());
        },
        validateCoupon(code:string){
            const data = {
                code: code
            }
            return axios.post(`${API.API_URL}/coupons/validate`,data,config())
        }
    },
    zipCode:{
        verifyZipCode(zipCode:string){
            const data = {
                zipCode:zipCode
            }
            return axios.post(`${API.API_URL}/zipcodes/validateZipCode`,data,config())
        },
        shouldAskForZipCode(){
            return axios.get(`${API.API_URL}/zipcodes/shouldAskForZipCode`, config());
        }
    },
    store: {
        getAvailablePaymentMethods() {
            return axios.get(`${API.API_URL}/store/paymentMethods`, config());
        },
        getCarousel(){
            return axios.get(`${API.API_URL}/store/carousel`, config());
        }
    },
    vehicles: {
        getOperatorInfo() {
            return axios.get(`${API.API_URL}/vehicles/operator/info`, config());
        },
    },
    lists: {
        get() {
            return axios.get(`${API.API_URL}/lists/`, config());
        },
    },
}
export default API;
