/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */

import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonButton,
    IonText,
    IonItem,
    IonLabel,
    IonInput,
    IonContent,
    IonPage,
    IonToast,
    IonNote,
    useIonAlert, IonCheckbox
} from '@ionic/react';
import {RouteComponentProps,useHistory} from "react-router";
import {FacebookLogin, FacebookLoginResponse} from '@capacitor-community/facebook-login';
import NbioApi from "../../lib/api/NbioApi";
import { GoogleAuth } from '@reslear/capacitor-google-auth';
import { getPlatforms } from '@ionic/react';
//apple sign-in
import {
    SignInWithApple,
    SignInWithAppleResponse,
    SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';
import {AppContext} from "../../App";
import axios from "axios";
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { isPlatform } from '@ionic/react';
//images
import {logoGoogle, logoFacebook} from 'ionicons/icons';

//style
import '../../css/pages/Login.css';



const Login: React.FC = () => {
    const history = useHistory();
    const GET_TOKEN_URL = 'https://oauth2.googleapis.com/token'
    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');
    const {user, setUser,isAuth,setIsAuth} = useContext(AppContext);
    const [isToastOpen,setIsToastOpen] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');
    const [isKeyboardOpen,setIsKeyboardOpen] = useState(false);
    const [presentAlert] = useIonAlert();
    const ionInputEmail = useRef(null);
    const ionInputPassword = useRef(null);
    const [isUnsupportedWebView,setIsUnsupportedWebView] = useState(false);
    const [showWelcomeMessage,setShowWelcomeMessage] = useState(false);
    const isKeyboardAvailable = Capacitor.isPluginAvailable('Keyboard');

    if(isKeyboardAvailable){
        Keyboard.addListener('keyboardWillShow', info => {
            setIsKeyboardOpen(true);
        });

        Keyboard.addListener('keyboardDidShow', info => {
            setIsKeyboardOpen(true);
        });

        Keyboard.addListener('keyboardWillHide', () => {
            setIsKeyboardOpen(false);
        });

        Keyboard.addListener('keyboardDidHide', () => {
            setIsKeyboardOpen(false);
        });
    }

    const goToStart = () =>{
        window.location.href = '/inicio';
    }
    useEffect(() =>{
        // Check props
        const state:any = history.location.state;
        setShowWelcomeMessage(state?.showWelcomeMessage);
    },[])

    const login = (e: any) => {
        e.preventDefault();
        NbioApi.users.login(email, password).then((res) => {
            // forget cart
            // Cart.forgetCart();
            if(res.data.result.user.roles.includes('operator')){
                window.location.replace(`/inicio`);
            }else{
                //google tracking
                try{
                    // @ts-ignore
                    // @ts-ignore
                    gtag("event", "login", {method: "nbio_Mx"});
                }catch(ex){
                }
                setUser(res.data.result.user);
                setIsAuth(true);
                goToStart();
            }
        }).catch((ex) => {
            try{
                let errorMessage = ex.response.data.result.reason_es;
                setErrorMessage(errorMessage);
                setIsToastOpen(true);
            }catch(ex){

            }
        })
    }
    useEffect(() =>{
        // Check if this is an unsupported webview (instagram, facebook in-app browser)
        const userAgent = window.navigator.userAgent.toLowerCase();
        if( userAgent.includes('fb_iab') ||
            userAgent.includes('fb4a') ||
            userAgent.includes('fbav') ||
            userAgent.includes('instagram') ||
            userAgent.includes('musical_ly')
        ){
            setIsUnsupportedWebView(true);
        }
    },[]);
    useEffect(() =>{
        GoogleAuth.initialize({
            clientId: '18257496481-e9k3e0ocq6mefenvdq6dgml13ufl7e94.apps.googleusercontent.com',
            scopes: ['profile', 'email'],
            grantOfflineAccess: true,
        })
    })
    const askDownloadApp = () =>{
        const isIos = isPlatform('ios');
        presentAlert({
            header: 'Descarga nuestra app',
            message:
                `Para una mejor experiencia, descarga nuestra app`,
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                },
                {
                    text: isIos ? 'Ir a la App Store' : 'Ir a Google Play',
                    role: 'confirm',
                    handler: () => {
                        goToStore();
                    },
                },
            ],
        })
    }
    const goToStore = () =>{
        const isIos = isPlatform('ios');
        window.open(isIos ? 'https://apps.apple.com/us/app/nbio/id1605823846' : 'https://play.google.com/store/apps/details?id=mx.nbio&hl=es_MX&gl=US');
    }
    const register = () =>{
        GoogleAuth.signIn().then((res) =>{
            if(!res.authentication.accessToken){
                // GET TOKEN
                const body = {
                    client_id: '18257496481-hu6kfsu44mp5lger9hhb0lq0nlrnn5t3.apps.googleusercontent.com',
                    client_secret: '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',
                    code: res.serverAuthCode,
                    grant_type: 'authorization_code',
                    redirect_uri: 'urn:ietf:wg:oauth:2.0:oob'
                };
                const tokenRes = axios.post(GET_TOKEN_URL,body).then((res) =>{
                    // Login successful.
                    const token = res.data.access_token;
                    // Login successful.
                    NbioApi.users.loginWithSocial(token,'google').then((res) => {
                        // forget cart
                        // Cart.forgetCart();

                        //google tracking
                        try{
                            // @ts-ignore
                            // @ts-ignore
                            gtag("event", "login", {method: "google"});
                        }catch(ex){
                        }
                        setUser(res.data.result.user);
                        setIsAuth(true);
                        goToStart();
                    }).catch((ex) => {
                        let errorMessage = "Hubo un error al iniciar sesión";
                        try{
                            errorMessage = ex.response.data.result.reason_es;
                        }catch(ex){
                        }
                        setErrorMessage(errorMessage);
                        setIsToastOpen(true);
                    })
                }).catch((ex) =>{
                    console.log('error',ex.response)
                })
            }else{
                // Login successful.
                NbioApi.users.loginWithSocial(res.authentication.accessToken,'google').then((res) => {
                    // forget cart
                    // Cart.forgetCart();
                    try{
                        // @ts-ignore
                        // @ts-ignore
                        gtag("event", "login", {method: "google"});
                    }catch(ex){
                    }
                    setUser(res.data.result.user);
                    setIsAuth(true);
                    goToStart();
                }).catch((ex) => {
                    let errorMessage = "Hubo un error al iniciar sesión";
                    try{
                        errorMessage = ex.response.data.result.reason_es;
                    }catch(ex){
                    }
                    setErrorMessage(errorMessage);
                    setIsToastOpen(true);
                })
            }

        }).catch((ex) =>{
        })
    }
    const loginWithApple = async () =>{
        let options: SignInWithAppleOptions = {
            clientId: 'mx.nbio.signin',
            redirectURI: 'https://api.nbio.mx/users/login/social',
            scopes: 'email name',
            state: '12345',
            nonce: 'nonce',
        };

        SignInWithApple.authorize(options)
            .then((result: SignInWithAppleResponse) => {
                // Handle user information
                // Validate token with server and create new session
                // Login successful.
                const extraData = {
                    givenName: result.response?.givenName || null,
                    familyName: result.response?.familyName || null
                }
                NbioApi.users.loginWithSocial(result.response.identityToken,'apple',extraData).then((res) => {
                    // forget cart
                    // Cart.forgetCart();
                    try{
                        // @ts-ignore
                        // @ts-ignore
                        gtag("event", "login", {method: "apple"});
                    }catch(ex){
                    }
                    setUser(res.data.result.user);
                    setIsAuth(true);
                    goToStart();
                }).catch((ex) => {
                    let errorMessage = "Hubo un error al iniciar sesión";
                    try{
                        errorMessage = ex.response.data.result.reason_es;
                    }catch(ex){
                        console.log(ex)
                    }
                    setErrorMessage(errorMessage);
                    setIsToastOpen(true);
                })
            })
            .catch(error => {
                console.log(error)
                // Handle error
            });
    }

    const loginWithFacebook = async () =>{
        const FACEBOOK_PERMISSIONS = ['email', 'user_birthday', 'public_profile', 'user_gender'];
        try{
            await FacebookLogin.initialize({ appId: '1011759540268928' });
            const result = await FacebookLogin.login({permissions: FACEBOOK_PERMISSIONS});
            if (result.accessToken) {
                // Login successful.
                NbioApi.users.loginWithSocial(result.accessToken.token,'meta').then((res) => {
                    // forget cart
                    // Cart.forgetCart();
                    try{
                        // @ts-ignore
                        // @ts-ignore
                        gtag("event", "login", {method: "meta"});
                    }catch(ex){
                    }
                    setUser(res.data.result.user);
                    setIsAuth(true);
                    goToStart();
                }).catch((ex) => {
                    let errorMessage = "Hubo un error al iniciar sesión";
                    try{
                        errorMessage = ex.response.data.result.reason_es;
                    }catch(ex){
                    }
                    setErrorMessage(errorMessage);
                    setIsToastOpen(true);
                })
            }
        }catch(ex){
            console.log(ex);
        }

    }

    const goToSignIn = () =>{
        history.push('/crear-cuenta');
    }
    const goNext = async (currentField:string ) =>
    {
        if (currentField === 'email') {
            try {
                // @ts-ignore
                ionInputPassword.current.setFocus();
            } catch (ex) {
            }
        }
    }
    const platforms = getPlatforms();
    const showAppleSignIn = platforms.includes('ios') || platforms.includes('iphone') || platforms.includes('ipad');
    return (
        <IonPage>
            <IonContent fullscreen>
                <IonToast
                    isOpen={isToastOpen}
                    onDidDismiss={() => {
                        setIsToastOpen(false);
                    }}
                    message={errorMessage}
                    duration={8000}
                />
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size-md="6">
                            {
                                isKeyboardOpen ? null :
                                    <>
                                        <div className={'main-app-logo'}></div>
                                        <div className={'ion-padding-top'}>
                                            {
                                                showWelcomeMessage ?
                                                    <div className={'ion-text-center'}>
                                                        <h5>Aún no has iniciado sesión.<br/> No te pierdas de la experiencia Nbio.</h5>
                                                    </div>  : null
                                            }
                                            <div className="ion-text-center ion-margin-bottom ion-hide-md-up">
                                                <IonText color={'medium'}>
                                                    <small>
                                                        Unirse con:
                                                    </small>
                                                </IonText>
                                            </div>
                                            <IonText className="ion-text-center ion-hide-md-down">
                                                <h5>Unirse con </h5>
                                            </IonText>
                                            <div className={'social-sign-buttons'}>
                                                <IonButton class={'social-button'}
                                                           // onClick={() => register()}
                                                           onClick={() => {
                                                               if(isUnsupportedWebView){
                                                                    askDownloadApp();
                                                               }else{
                                                                   register();
                                                               }
                                                           }}
                                                >
                                                    <IonIcon slot="start" icon={logoGoogle} className='social-icon'></IonIcon>
                                                </IonButton>
                                                <IonButton class={'social-button'}
                                                           // onClick={() => loginWithFacebook()}
                                                           onClick={() => {
                                                               if(isUnsupportedWebView){
                                                                   askDownloadApp();
                                                               }else{
                                                                   loginWithFacebook();
                                                               }
                                                           }}
                                                >
                                                    <IonIcon slot="start" icon={logoFacebook} className='social-icon'></IonIcon>
                                                </IonButton>
                                                {
                                                    showAppleSignIn ?
                                                        <IonButton class={'social-button'}
                                                                   // onClick={() => loginWithApple()}
                                                                   onClick={() => {
                                                                       if(isUnsupportedWebView){
                                                                           askDownloadApp();
                                                                       }else{
                                                                           loginWithApple();
                                                                       }
                                                                   }}
                                                        >
                                                            <div slot="start" className='social-icon apple-icon'>

                                                            </div>
                                                        </IonButton>
                                                        : null
                                                }
                                            </div>
                                            <IonText className="ion-text-center ion-hide-md-down">
                                                <h5>O</h5>
                                            </IonText>
                                            <div className="ion-text-center ion-hide-md-up">
                                                <IonText color={'medium'}>
                                                    <small>
                                                        O
                                                    </small>
                                                </IonText>
                                            </div>
                                        </div>
                                    </>
                            }
                            <IonItem>
                                <IonLabel position={"floating"}>Correo</IonLabel>
                                <IonInput id="email"
                                          type="text"
                                          value={email}
                                          ref={ionInputEmail}
                                          onKeyPress={(ev) => {
                                              if(ev.key === 'Enter'){
                                                  goNext('email');
                                              }
                                          }}
                                          enterkeyhint="next"
                                          onIonChange={(e: any) => setEmail(e.target.value)}
                                          placeholder="Correo">
                                </IonInput>
                            </IonItem>

                            <IonItem>
                                <IonLabel position={"floating"}>Contraseña</IonLabel>
                                <IonInput id="password"
                                          type="password"
                                          value={password}
                                          ref={ionInputPassword}
                                          onIonChange={(e: any) => setPassword(e.target.value)}
                                          onKeyPress={(ev) => {
                                              if(ev.key === 'Enter'){
                                                  login(ev);
                                              }
                                          }}

                                          enterkeyhint={'go'}
                                          placeholder="Contraseña">
                                </IonInput>
                            </IonItem>
                            <IonItem className={'bg-transparent ion-hide-md-up'} lines={'none'}>
                                <IonNote className={'ion-text-wrap ion-margin-top'}>
                                    <small>
                                        Al iniciar sesión, acepto las
                                        <a href={'/politicas-de-privacidad'}>
                                        &nbsp;Políticas de Privacidad&nbsp;
                                        </a>
                                        y<a href={'/terminos-y-condiciones'}>
                                        &nbsp;Términos y Condiciones&nbsp;
                                        </a> de Nbio.
                                    </small>
                                </IonNote>
                            </IonItem>
                            <IonItem className={'bg-transparent ion-hide-md-down'} lines={'none'}>
                                <IonNote className={'ion-text-wrap ion-margin-top'}>
                                        Al iniciar sesión, acepto las
                                        <a href={'/politicas-de-privacidad'}>
                                            &nbsp;Políticas de Privacidad&nbsp;
                                        </a>
                                        y<a href={'/terminos-y-condiciones'}>
                                        &nbsp;Términos y Condiciones&nbsp;
                                    </a> de Nbio.
                                </IonNote>
                            </IonItem>
                            <IonButton className={"ion-margin-vertical"}
                                       expand="block"
                                       onClick={(e) => login(e)}>Entrar</IonButton>

                            <IonButton expand="block"
                                       fill="outline"
                                       onClick={goToSignIn}>Crear cuenta</IonButton>


                            <div className={'flex-row ion-justify-content-between ion-wrap'}>
                                <IonButton fill="clear"
                                           size="small"
                                           onClick={() => goToStart()}
                                           >Continuar sin cuenta</IonButton>
                                <IonButton fill="clear"
                                           size="small"
                                           routerLink={"/solicitar-cambio-de-contrasena"}
                                           routerDirection={"forward"}>Olvidé mi contraseña</IonButton>
                            </div>

                            <IonNote className={'ion-text-center w-100 flex-row ion-justify-content-center'}>
                                <small>
                                    v1.51.0
                                </small>
                            </IonNote>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    )
}

export default Login;
